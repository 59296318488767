// extracted by mini-css-extract-plugin
export var CompanyCulture = "k_dd";
export var CompanyCulture__title = "k_df";
export var CompanyCulture__messages = "k_dg";
export var CompanyCulture__topMessage = "k_dh";
export var CompanyCulture__image = "k_dj";
export var CompanyCulture__middleMessage = "k_dk";
export var CompanyCulture__shinyEmployeesImage = "k_dl";
export var CompanyCulture__shinyEmployeesSp = "k_dm";
export var CompanyCulture__shinyEmployeesMessage = "k_dn";
export var CompanyCulture__value = "k_dp";
export var CompanyCulture__valueMessage = "k_dq";
export var CompanyCulture__valueMessageNumber = "k_dr";
export var CompanyCulture__valueMessageText = "k_ds";
export var CompanyCulture__cultivatedCultureTitle = "k_dt";
export var CompanyCulture__cultivatedCulture = "k_dv";
export var CompanyCulture__cultivatedCultureMessage = "k_dw";
export var CompanyCulture__cultivatedCultureImage = "k_dx";
export var CompanyCulture__cultivatedCultureImageSp = "k_dy";
export var CompanyCulture__cultivatedCultureText = "k_dz";
export var CompanyCulture__supportTitle = "k_dB";
export var CompanyCulture__support = "k_dC";
export var CompanyCulture__supportMessage = "k_dD";
export var CompanyCulture__supportMessageLeft = "k_dF";
export var CompanyCulture__supportMessageRight = "k_dG";
export var CompanyCulture__supportMessageRightImage = "k_dH";
export var CompanyCulture__supportMessageRightImageSp = "k_dJ";