import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/company/culture.module.scss";
import { Link } from "gatsby";
import { TitleCover } from "../../atoms/title-cover/TitleCover";
import ShinyImg from "../../images/culture/_kpo1234_re.jpg";
import SupportImg1 from "../../images/culture/sbc0213_re.jpg";
import SupportImg2 from "../../images/culture/sbc0180.jpg";
import cultureImgSp1 from "../../images/culture/cultureImgSp001.jpg";
import cultureImgSp3 from "../../images/culture/cultureImgSp003.jpg";
import cultureImgSp4 from "../../images/culture/cultureImgSp004.jpg";
import cultureImgSp5 from "../../images/culture/cultureImgSp005.jpg";
import cultureImgSp6 from "../../images/culture/cultureImgSp006.jpg";
import cultureImgSp7 from "../../images/culture/cultureImgSp007.jpg";

import cultureImg5 from "../../images/culture/_kpo0766.jpg";
import cultureImg4 from "../../images/culture/sbc0165.jpg";
import cultureImg3 from "../../images/culture/_kpo0813.jpg";

import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/culture/sbc0416.jpg";

export default ({ location: { pathname } }) => {
  const [isWhite, setIsWhite] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsWhite(true);
      } else {
        setIsWhite(false);
      }
    });

    const targets = document.querySelectorAll(
      "[data-change-header-color-point]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });
  }, []);

  return (
    <DefaultLayout isWhite={isWhite} className={styles.CompanyCulture}>
      <SEO
        pathname={pathname}
        title="カルチャー | culture"
        description="スプリーブホールディングスが掲げるビジョン、ミッション、バリューやそれを支える制度を紹介します。"
        image={seoImage}
      />
      <div className={styles.CompanyCulture__title}>
        <TitleCover
          title="Culture"
          sub="スプリーブの文化"
          background={
            <StaticImage
              src="../../images/culture/sbc0416.jpg"
              alt="スプリーブの文化"
              style={{ width: "100%", height: "100%" }}
            />
          }
        />
      </div>

      {/* Intersection Observer 用にヘッダーのカラーを変えるポイントを仕込む */}
      <div data-change-header-color-point="data-change-header-color-point" />

      <div className={styles.CompanyCulture__messages}>
        <div className={styles.CompanyCulture__topMessage}>
          <div className="pc-only">
            <h2>
              最強のチームから
              <br />
              この上ない最高の世界が始まる
            </h2>
          </div>
          <div className="sp-only">
            <h2>最強のチームからこの上ない最高の世界が始まる</h2>
          </div>
        </div>
        <h4>The best world starts from the best team.</h4>
        <p>
          私たちには、この上ない最高の世界を創るという夢があります。
          <br />
          それは、
          <br />
          人と人が傷つけあうことのない世界。
          <br />
          自然が枯れてしまうことのない世界。
          <br />
          人の旅立ちに悲しむことのない世界。
          <br />
          朝、目覚めることが楽しみになる世界。
          <br />
          1人ひとりが思い浮かべる最高の世界のカタチがあります。
          <br />
          最高の世界を創り出す覚悟と強い意志を持つメンバーが集まることで、最強のチームが生まれます。
          <br />
          そんな最強チームから、最高の世界は始まります。
          <br />
          さあ、未来を創ろう。
        </p>
      </div>

      <StaticImage
        className={styles.CompanyCulture__image}
        src="../../images/culture/_kpo0858_culture.jpg"
        alt=""
      />

      <div className={styles.CompanyCulture__middleMessage}>
        <h3>Vision</h3>
        <h2>この上ない最高の世界を創る</h2>
        <h3>Mission</h3>
        <h4>全ての事業をリーディングカンパニーへ</h4>
        <Link to="/company/vision/">View All</Link>
      </div>

      <div>
        <img
          className={styles.CompanyCulture__shinyEmployeesImage}
          src={ShinyImg}
          alt=""
        />
        <img
          className={styles.CompanyCulture__shinyEmployeesSp}
          src={cultureImgSp1}
          alt=""
        />

        <div className={styles.CompanyCulture__shinyEmployeesMessage}>
          <h2>Shiny employees</h2>
          <h3>Suprieveは、人と叶えます</h3>
          <p>
            Visionとは、将来のありたい姿であり、Missionとは、Visionのために、Suprieveが果たすべき手段です。それを実現するのは、Suprieveの中心にいる人です。Suprieveは同じ文化を共有することで、人と人の繋がりを強め、力強く事業を推進していきます。
          </p>
        </div>
      </div>

      <div className={styles.CompanyCulture__value}>
        <h2>Value</h2>
        <h3>VisionとMissionを実現するための志</h3>

        <div className={styles.CompanyCulture__valueMessage}>
          <div className={styles.CompanyCulture__valueMessageNumber}>
            <h2>01</h2>
          </div>
          <div className={styles.CompanyCulture__valueMessageText}>
            <h2>Think deeply</h2>
            <p>
              世界をリードするためには、イノベーションが欠かせません。
              <br />
              そのためには、仕事に夢中になり、1人で深く集中すること、そして仲間と共に生み出さなければなりません。
            </p>
          </div>
        </div>

        <div className={styles.CompanyCulture__valueMessage}>
          <div className={styles.CompanyCulture__valueMessageNumber}>
            <h2>02</h2>
          </div>
          <div className={styles.CompanyCulture__valueMessageText}>
            <h2>More Excitement</h2>
            <p>
              人生の多くの時間を占めるのが「仕事」です。
              <br />
              仕事では数々の苦難、困難があります。
              <br />
              私たちは「仕事を楽しむには」という視点を常に意識しております。
              <br />
              チャレンジすることにわくわくし、失敗もまた成長と捉え、すべての人から学び、目標達成の喜びを仲間と分かち合う。日々わくわくを育んでいきます。
            </p>
          </div>
        </div>

        <div className={styles.CompanyCulture__valueMessage}>
          <div className={styles.CompanyCulture__valueMessageNumber}>
            <h2>03</h2>
          </div>
          <div className={styles.CompanyCulture__valueMessageText}>
            <h2>10x Faster and Bigger</h2>
            <p>
              私たちはこれまでに「どんな会社よりも速い行動」を「どんな会社よりも大きい計画」を遂行してきました。これもSuprieveグループを拡大していくためです。日本に世界に、多岐に渡るさまざまなサービスを展開していき、これからも成長スピードを止めず進み続けます。
            </p>
          </div>
        </div>

        <div className={styles.CompanyCulture__valueMessage}>
          <div className={styles.CompanyCulture__valueMessageNumber}>
            <h2>04</h2>
          </div>
          <div className={styles.CompanyCulture__valueMessageText}>
            <h2>One Suprieve</h2>
            <p>
              どんな苦境に立たされたとしてもSuprieveは団結し、メンバーを見捨てず、共に知恵を出し切ることで、どんな大きな壁でも必ず乗り越えてみせます。
            </p>
          </div>
        </div>
      </div>

      <StaticImage
        className={styles.CompanyCulture__image}
        src="../../images/culture/sbc0015.jpg"
        alt=""
      />
      <></>

      <div className={styles.CompanyCulture__cultivatedCultureTitle}>
        <h2>Cultivated culture</h2>
        <h3>醸成されるカルチャー</h3>
      </div>

      <div className={styles.CompanyCulture__cultivatedCulture}>
        <div className={styles.CompanyCulture__cultivatedCultureMessage}>
          <img
            className={styles.CompanyCulture__cultivatedCultureImage}
            src={cultureImg3}
            alt=""
          />
          <img
            className={styles.CompanyCulture__cultivatedCultureImageSp}
            src={cultureImgSp3}
          />
          <div className={styles.CompanyCulture__cultivatedCultureText}>
            <h2>覚悟を決め、成し遂げる</h2>
            <h3>Determine and achieve.</h3>
            <p>
              「この上ない最高」を実現するためには、各業界または各役割の中であらゆる困難が待ち構えています。関わるプロジェクトも他の会社では経験できないようなワクワクするものが数多くあります。能力を飛躍的に伸ばしていける環境では、このマインドは欠かせません。
            </p>
          </div>
        </div>

        <div className={styles.CompanyCulture__cultivatedCultureMessage}>
          <img
            className={styles.CompanyCulture__cultivatedCultureImage}
            src={cultureImg4}
          />
          <img
            className={styles.CompanyCulture__cultivatedCultureImageSp}
            src={cultureImgSp4}
          />
          <div className={styles.CompanyCulture__cultivatedCultureText}>
            <h2>挑戦HOLIC</h2>
            <p>
              私たちは、最初から諦めることをしません。
              <br />
              実現できない理由を探すより、実現する方法を見つけ出し、まずは挑戦してみる文化があります。
              <br />
              機会を見つけていつでも、どんな高さでも思い切り飛び込むことができるように、私たちは毎日、1%でもアップデートすることを心がけています。
            </p>
          </div>
        </div>

        <div className={styles.CompanyCulture__cultivatedCultureMessage}>
          <img
            className={styles.CompanyCulture__cultivatedCultureImage}
            src={cultureImg5}
            alt=""
          />
          <img
            className={styles.CompanyCulture__cultivatedCultureImageSp}
            src={cultureImgSp5}
          />
          <div className={styles.CompanyCulture__cultivatedCultureText}>
            <h4>「友達」という考え方</h4>
            <p>
              私たちが創業以来、ずっと大切にしているのは「友達」という考え方です。
              <br />
              「この人たちと、一生一緒に働きたい。」
              <br />
              せっかく仕事をするのなら、心から信頼できる相手と一緒に、今日も明日もわくわくしながら働きたい。
              <br />
              だからこそ私たちは、一緒に働く仲間を「友達」と呼び、1人ひとりが安心して、自分が自分らしく働けるように、オープンなコミュニケーションを行っています。
            </p>
          </div>
        </div>
      </div>

      <div className={styles.CompanyCulture__supportTitle}>
        <h2>Culture supporting event/system</h2>
        <h3>カルチャーを支える制度</h3>
      </div>

      <div className={styles.CompanyCulture__support}>
        <div className={styles.CompanyCulture__supportMessage}>
          <div className={styles.CompanyCulture__supportMessageLeft}>
            <h2>ビジネスプランニングコンテスト</h2>
            <p>
              社内制度の1つとして、2017年にビジネスプランニングコンテスト（別名：新規事業立案制度）が誕生しました。「夢」や社会への「貢献」を実現したい！そんな熱い想いを企画・提案する場所であり、Suprieve
              Holdingsの全社員、内定者であれば、立場や役職関係なく誰でも参加が可能です。
            </p>
            <h2>四半期ごとの目標設定</h2>
            <p>
              人として、組織として成長をし続けるために、各事業部、課毎に四半期ごとの目標を設定し、評価、振り返りを行っています。
            </p>
            <h2>メンター制度</h2>
            <p>
              未経験の方も安心して働いていただけるよう、年齢や社歴の近い先輩社員が助言等を行うメンター制度を導入しています。
            </p>
            <h2>社内FA制度</h2>
            <p>
              社員が経歴や能力・実績を希望部署に提示し、異動や転籍を可能とする人事異動制度のひとつです。各子会社/事業部が人員増加を希望する際に実施しています。Suprieveのホールディングスの中でジョブチェンジを実現することができます。
            </p>
            <h2>社員総会</h2>
            <p>
              年に1回、社員が参加する社員総会を実施しています。オープンに1年間の業績報告、会社が目指す方向などの振り返りを行い、全社員の意識合わせを行います。
            </p>
          </div>
          <div className={styles.CompanyCulture__supportMessageRight}>
            <img
              className={styles.CompanyCulture__supportMessageRightImage}
              src={SupportImg1}
              alt=""
            />
            <img
              className={styles.CompanyCulture__supportMessageRightImage}
              src={SupportImg2}
              alt=""
            />

            <img
              className={styles.CompanyCulture__supportMessageRightImageSp}
              src={cultureImgSp6}
              alt=""
            />
            <img
              className={styles.CompanyCulture__supportMessageRightImageSp}
              src={cultureImgSp7}
              alt=""
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
